body {
  background-color: antiquewhite;
}

.post-list {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.post-card {
  margin: 0.6em;
  width: 35em;
  max-width: 85% !important;
}

.new-post-icon {
  position: fixed !important;
  bottom: 4%;
  right: 3%;
}